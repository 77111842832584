import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const CKEditorInput = (props) => {
  const { handleChangeEditor, defaultContent } = props
  const [state, setState] = useState()
  const myref = useRef()

  useEffect(() => {
    const IDCK = document.getElementById('CKEditorInput')
    if (myref.current && IDCK) {
      if (IDCK.children.length >= 1) {
        for (let index = 0; index < IDCK.children.length; index++) {
          const e = IDCK.children[index]
          if (index >= 2) {
            IDCK.removeChild(e)
          }
        }
      }
    }
  }, [state])

  return (
    <div>
      <div id='CKEditorInput' ref={myref}>
        <CKEditor
          ref={props?.ref}
          name='body'
          onReady={editor => {
            setState(Math.random())
          }}
          editor={ClassicEditor}
          data={defaultContent ? defaultContent : '<p></p>'}
          onChange={handleChangeEditor}
        />
      </div>
    </div>
  )
}

export default CKEditorInput