import { identifierLocalStorage } from "../../utils/localstorage"


export function AuthController(value) {
  // this.Login = () => ''
  // this.Logout = () => ''
  // this.sendOTP = () => ''
  // this.forgotPassord = () => ''
  // this.resetPassword = () => ''
  // this.updateTokenFirebase = () => ''
  // this.refreshToken = () => ''
  // this.checkToekn = () => ''
  // this.storeLocal = () => ''
}

AuthController.prototype.Login = function () { }
AuthController.prototype.Logout = function () {
  return identifierLocalStorage.clear()
}
AuthController.prototype.sendOTP = function () { }
AuthController.prototype.forgotPassord = function () { }
AuthController.prototype.resetPassword = function () { }
AuthController.prototype.updateTokenFirebase = function () { }
AuthController.prototype.refreshToken = function () { }
AuthController.prototype.checkToekn = function () { }
AuthController.prototype.storeLocal = function () { }

export const keepCookie = (state) => {
  localStorage.setItem('_ident', JSON.stringify(state))
}

const emailAllow = ['myseradmin@gmail.com', 'hello_04@gmail.com']

export const checkIsAdmin = (authUser) => {
  return true
  // if (!authUser?.email) return false
  // return emailAllow.includes(authUser.email)
} 