import React, { useState } from 'react'
import styles from './InputOptionMulti.module.css'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';

// https://mui.com/components/selects/

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function InputOptionMulti(props) {
  const [label, setLabel] = useState(props?.label || '')
  const [nameSelected, setNameSelected] = React.useState([])

  const handleChange = (event) => {
    const { target: { value }, } = event;
    setNameSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  return (
    <div className={styles.rootInputSelect}>
      <FormControl className={styles.formControl}>
        <InputLabel id='label-Id'>{label}</InputLabel>
        <Select
          placeholder={label}
          labelId='label-Id'
          id={props?.id ? props.id : 'selecID'}
          multiple
          value={nameSelected}
          onChange={handleChange}
          input={<OutlinedInput id={props?.id ? props.id : 'selecID'} label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (<Chip key={value} label={value} />))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {
            props?.listItem ? props?.listItem.map((lesson) => (
              <MenuItem
                key={lesson}
                value={lesson}
              // style={getStyles(name, personName, theme)}
              >
                {lesson}
              </MenuItem>
            ))
              :
              []
          }
        </Select>
      </FormControl>
      <Button variant="outlined" className={styles.btnClear}>Clear Options</Button>
    </div>
  )
}

export default InputOptionMulti
