import React from 'react'

function Setting() {
  return (
    <div>
      <h1>ตั้งค่าเว็บไซต์</h1>
      <h3>แอป</h3>
      <h3>เมนู/เรื่องหลัก</h3>
      <h3>การแสดงผล</h3>
      <h3>ตรวจสอบ</h3>
      <h3>มาตรฐานการพัฒนา</h3>
      <h3>กฎหมาย</h3>
    </div>
  )
}

export default Setting