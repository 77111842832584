import { Link } from 'react-router-dom'
import React from 'react'
import EnhancedTable from '../../components/table/EnhancedTable'
import styles from './Question.module.css'
import { Button } from '@mui/material'

export default function Question() {
  return (
    <div>
      <div className={styles.headerContent}>
        <h1>Question Page</h1>
        <Link to={'/question/add'}>
          <Button variant="contained">เพิ่ม Question</Button>
        </Link>
      </div>
      <EnhancedTable />
    </div>
  )
}
