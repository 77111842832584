import React, { Fragment } from 'react'
import { Button } from '@mui/material'
import styles from './LayoutForm.module.css'

function LayoutForm(props) {
  // const [header,] = useState(props?.header)
  // const [body] = useState(props?.bodyForm)
  // const [rishText, setRishText] = useState(props?.rishText)
  // const [dateForm,] = useState(props?.dateForm)
  // const [footer,] = useState(props?.footer)

  return (
    <div className={styles.rootForm}>
      <div className={styles.headerForm}>
        {props?.header && <h1>{props?.header}</h1>}
      </div>
      <div className={styles.bodyForm}>
        {props?.bodyForm && props?.bodyForm}
      </div>
      <div className={styles.dateForm}>
        {props?.dateForm && props?.dateForm}
      </div>
      <div className={styles.footerForm}>
        {
          <Fragment>{props?.footer && props?.footer}
            < div className={styles.divBtn} >
              <Button className={styles.BtnCancel} variant="outlined" >cancel</Button>
              <Button className={styles.BtnSave} variant="contained">save</Button>
            </div>
          </Fragment>
        }
      </div>
    </div >
  )
}

export default LayoutForm
