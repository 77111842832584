import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from './Content.module.css'
import EnhancedTable from '../../components/table/EnhancedTable'
import { useQuery } from '@tanstack/react-query'
import { ContentListAPI } from '../../api/content'


function Content() {
  const { isLoading, error, data } = useQuery(['fetchContentList'], () => ContentListAPI())
  useEffect(() => {

  }, [])


  return (
    <div>
      <div className={styles.headerContent}>
        <h1>Content</h1>
        <Link to={'/content/add'}>
          <Button variant="contained">เพิ่มบทความ</Button>
        </Link>
      </div>
      {error && <div> Error </div>}
      {isLoading && <div> ... loading </div>}
      {!error && !isLoading &&
        <EnhancedTable
          title={'บทความ'}
          canEdit
          list={data}
          showFields={[
            'title',
            'category',
            'minRead',
            'viewer',
            'searchScore',
            'createAt',
            'updateAt',
            'public',
          ]}
        />}
    </div>
  )
}
export default Content

// export default (props) => <QueryClientProvider client={queryClient}> <Content {...props} /> </QueryClientProvider>
