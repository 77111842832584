export const LOCAL_STORAGE_KEYS = {
  Identifier: '_ident',
  OTP: 'PS_TPLE',
  FirebaseToken : '_fsbtk'
}

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || ''

export const USER_ROLE = {
  STUDENT: 'Student',
  TEACHER: 'Teacher',
  GUEST: 'GUEST'
}