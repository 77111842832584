import { createSlice } from '@reduxjs/toolkit'

const defaultValue = {
  displayName: '',
  email: '',
  password: '',
}

export const handleUser = createSlice({
  name: 'user',
  initialState: defaultValue,
  reducers: {
    handleChange: (state, action) => {
      state[action.payload.name] = action.payload.value
    },
    resetUser: (state) => {
      state = defaultValue
    }
  },
})

export const { handleChange, resetUser } = handleUser.actions
export default handleUser.reducer