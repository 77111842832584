import { Link } from 'react-router-dom'
import React from 'react'
import EnhancedTable from '../../components/table/EnhancedTable'
import styles from './Group.module.css'
import { Button } from '@mui/material'

export default function Group() {

  return (
    <div>
      <div className={styles.headerContent}>
        <h1>Content Page</h1>
        <Link to={'/group/add'}>
          <Button variant="contained">เพิ่มกลุ่ม</Button>
        </Link>
      </div>
      <EnhancedTable />
    </div >
  )
}
