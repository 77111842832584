import React from 'react';
import { TextField } from '@mui/material';

function InputForm(props) {

  return (
    <div>
      <TextField
        // onChange={props?.onChange}
        label={props?.label}
        variant='outlined'
        sx={{ width: '40ch' }} />
    </div>
  )
}

export default InputForm
