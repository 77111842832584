import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
// import { onChangeEmail, onChangePassword, onChangeDevice } from '../store/coreModels/handleAuth'
// import { SubmitLogin } from '../store/features/auth'
import styles from './LayoutLogin.module.css'
import { loginService } from '../api/auth'

import { checkMoblieBrowser } from 'api/auth'
import { deviceDetect } from 'react-device-detect'
import { idGenerate } from 'utils/helper'
import { Alert } from '@mui/material'
import { identifierLocalStorage } from 'utils/localstorage'

const deviceType = deviceDetect() ? (deviceDetect().browserName || checkMoblieBrowser(deviceDetect())) + ', ' + (deviceDetect().osName || deviceDetect().os + `, ${deviceDetect().isMobile ? 'Mobile' : deviceDetect().isTablet && 'Tablet'}`) : ''

const defaultState = {
  email: '',
  username: '',
  password: '',
  deviceType: deviceType,
  deviceId: idGenerate(deviceType),
}


function LayoutLogin() {
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [state, setState] = useState(defaultState)
  const [messageError, setMessageError] = useState()
  const fromPreviousOrHome = location.state?.from?.pathname || "/"

  useEffect(() => {
    console.log(process.env.REACT_APP_API_URL)
    if (identifierLocalStorage.get()) {
      navigate(fromPreviousOrHome, { replace: true })
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (state.email) {
      const res = await loginService(state)
      if (res?.error?.response?.message) {
        setMessageError(res.error.response.message)
      } else {
        return navigate(fromPreviousOrHome, { replace: true })
      }
    }

  }
  const handleChange = name => e => setState({ ...state, [name]: e.target.value })

  return (
    <div className={styles.rootLoginPage}>
      {messageError && <Alert className='alertMessage' severity="error"> {messageError}</Alert>}
      <form onSubmit={handleSubmit} className={styles.formLogin} method="post" target='_blank'>
        <input
          value={state?.email}
          // onChange={(e) => dispatch(onChangeEmail(e.target.value))}
          onChange={handleChange('email')}
          type='email'
          placeholder='email' />
        <br /><br />
        <input
          value={state?.password}
          // onChange={(e) => dispatch(onChangePassword(e.target.value))}
          onChange={handleChange('password')}
          type='password'
          placeholder='password' />
        <br /><br />
        <button type='submit'>submit</button>
      </form>
    </div>
  )
}

export default LayoutLogin;
