import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { Link } from 'react-router-dom';
import { INFO } from '../config/initModel';
import { Typography } from '@mui/material';
import styles from './ListMenuItem.module.css'
import { identifierLocalStorage } from 'utils/localstorage';

export const MainListItems = () => {
  return (
    <div>
      {INFO.MENU.map((list, i) => {
        if (list.allowRole.includes('all')) {
          return (
            <Link to={list.path} key={list.key}>
              <ListItem >
                <ListItemIcon>
                  {list.logo}
                </ListItemIcon>
                <p className={styles.listText}>
                  {list.text}
                </p>
              </ListItem>
            </Link>
          )
        } else if (
          list.allowRole.includes(identifierLocalStorage.get()?.role)
          || identifierLocalStorage.get()?.email === 'myemail_4@gmail.com') {
          return (
            <Link to={list.path} key={list.key}>
              <ListItem key={list.key} >
                <ListItemIcon>
                  {list.logo}
                </ListItemIcon>
                <p className={styles.listText}>
                  {list.text}
                </p>
              </ListItem>
            </Link>
          )
        }
        return <div key={i}> </div>
      })}
    </div>
  )
}

export const SecondaryListItems = () => {
  return (
    <div>
      <ListSubheader inset>รายงานสถิติ</ListSubheader>
      {INFO.SUB_MENU.map((list) => {
        return (
          <Link to={list.path} key={list.key}>
            <ListItem >
              <ListItemIcon>
                {list.logo}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography className={styles.listText}>
                    {list.text}
                  </Typography>} />
            </ListItem>
          </Link>
        )
      })}
    </div>
  )
}