import { configureStore } from '@reduxjs/toolkit'
import handleAuth from './coreModels/handleAuth'
import handleContent from './coreModels/handleContent'
import handleUser from './coreModels/handleUser'
import counterSlice from './features/counterSlice'

export default configureStore({
  reducer: {
    auth: handleAuth,
    dashboard: {
      chart: {},
      options: {},
      overview: {}
    },
    content: handleContent,
    user: handleUser,
    Question: {
      listQuestion: {},
      createQuestion: {},
      updateQuestion: {},
      deleteQuestion: {},
    },
    Group: {
      listGroup: {},
      createGroup: {},
      updateGroup: {},
      deleteGroup: {},
    },
    Team: {
      listTeam: {},
      createTeam: {},
      updateTeam: {},
      deleteTeam: {},
    },
    Jobs: {
      listJobs: {},
      createJobs: {},
      updateJobs: {},
      deleteJobs: {},
    },
    History: {
      listHistory: {},
      createHistory: {},
      updateHistory: {},
      deleteHistory: {},
    },
    NoteMonth: {
      listNoteMonth: {},
      createNoteMonth: {},
      updateNoteMonth: {},
      deleteNoteMonth: {},
    },
    NoteQuarter: {
      listNoteQuarter: {},
      createNoteQuarter: {},
      updateNoteQuarter: {},
      deleteNoteQuarter: {},
    },
    NoteYear: {
      listNoteYear: {},
      createNoteYear: {},
      updateNoteYear: {},
      deleteNoteYear: {},
    },
    counter: counterSlice,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  },
})