import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store/store'
import './global.css'
import "react-datepicker/dist/react-datepicker.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import 'draft-js/dist/Draft.css'
// import { createRoot } from 'react-dom/client'


// createRoot(document.getElementById('root')).render(
//     <Provider store={store}>
//       <App />
//     </Provider>
// )
ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
