import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const WEBSITE_TITLE = 'Dashboard Ai-tent.com'

export const MENU_LIST = [
  {
    key: 'dashBorad',
    text: 'แดชบอร์ด',
    path: '/',
    allowRole: ['all'],
    logo: <DashboardIcon />,
  },
  {
    key: 'Content',
    text: 'เนื้อหา',
    path: '/content',
    allowRole: ['all'],
    logo: <DashboardIcon />,
  },
  {
    key: 'Question',
    text: 'คำถาม',
    path: '/question',
    allowRole: ['all'],
    logo: <LayersIcon />,
  },
  {
    key: 'Group',
    text: 'กลุ่ม',
    path: '/group',
    allowRole: ['all'],
    logo: <PeopleIcon />,
  },
  {
    key: 'History',
    text: 'ประวัติ',
    path: '/history',
    allowRole: ['all'],
    logo: <BarChartIcon />,
  },
  {
    key: 'User',
    text: 'ผู้ใช้',
    path: '/user',
    allowRole: ['Admin'],
    logo: <PeopleIcon />,
  },
  {
    key: 'master',
    text: 'ข้อมูลหลัก',
    path: '/master',
    allowRole: ['Admin'],
    logo: <DashboardIcon />,
  },
  {
    key: 'setting',
    text: 'ตั้งค่า',
    path: '/setting',
    allowRole: ['Admin'],
    logo: <DashboardIcon />,
  },
]

export const SUB_MENU = [
  {
    key: 'CurrentMonth',
    text: 'สถิติรายเดือน',
    path: '/',
    allowRole: ['all'],
    logo: <AssignmentIcon />,
  },
  {
    key: 'CorrentQuarter',
    text: 'สถิติรายไตรมาส',
    path: '/',
    allowRole: ['all'],
    logo: <AssignmentIcon />,
  },
  {
    key: 'CurrentYear',
    text: 'สถิติรายปี',
    path: '/',
    allowRole: ['all'],
    logo: <AssignmentIcon />,
  },
]