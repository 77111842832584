import { Link } from 'react-router-dom'
import React from 'react'
import EnhancedTable from '../../components/table/EnhancedTable'
import styles from './History.module.css'
import { Button } from '@mui/material'

export default function History() {
  return (
    <div>
      <div className={styles.headerContent}>
        <h1>History Page</h1>
        <Link to={'/history/add'}>
          <Button variant="contained">เพิ่ม History</Button>
        </Link>
      </div>
      <EnhancedTable />
    </div>
  )
}
