import { Fragment, useEffect } from 'react'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import { dateAndTimeFromTimeStamp, dateFromTimeStamp } from 'utils/helper'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'

const HeaderCellContent = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'ชื่อบทความ',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'ประเภท',
  },
  {
    id: 'viewer',
    numeric: true,
    disablePadding: false,
    label: 'จำนวนคนดู',
  },
  {
    id: 'searchScore',
    numeric: true,
    disablePadding: false,
    label: 'อันดับการค้นหา',
  },
  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'ผู้เขียน',
  },
  {
    id: 'updateAt',
    numeric: true,
    disablePadding: false,
    label: 'วันที่อัพเดท',
  },
  {
    id: 'createAt',
    numeric: true,
    disablePadding: false,
    label: 'วันที่สร้าง',
  },
  {
    id: 'statusPublic',
    numeric: false,
    disablePadding: false,
    label: 'สาธารณะ',
  },
]

export function createDataContent(title, category, viewer, searchScore, author, updateAt, createAt, statusPublic) {
  return {
    title,
    category,
    viewer,
    searchScore,
    author,
    updateAt,
    createAt,
    statusPublic,
  }
}

export const defaultRowContent = [createDataContent('ชื่อบทความ', 'ประเภท', 10, 0, 'ผู้เขียน', 'วันที่ 1', 'วันที่ 2', 'สาธารณะ')]

export const BodyContent = (props) => {
  const { isItemSelected, labelId, record, list, showFields, canEdit } = props
  const location = useLocation()
  const getEdit = `${location.pathname}/edit/${record['_id']}`

  return (
    <Fragment>
      <TableCell padding='checkbox'>
        <Checkbox
          color='primary'
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      {
        showFields && showFields.map((field, i) => {
          if (record[field] === undefined) {
            return <TableCell key={`TableCell-Body=${i}`} align='center'></TableCell>
          }
          if (['title'].includes(field)) {
            return (
              <TableCell
                key={`TableCell-Body=${i}-${field}`}
                component='th'
                id={labelId}
                scope='row'
                padding='none'
              >
                {record[field]}
              </TableCell>
            )
          }
          if (['createAt', 'updateAt', 'lastLogin'].includes(field)) {
            return <TableCell key={`TableCell-Body=${i}-${field}`} align='center'>{dateAndTimeFromTimeStamp(new Date(record[field]))}</TableCell>
          }
          return <TableCell key={`TableCell-Body=${i}-${field}`} align={field === 'title' ? 'left' : 'center'} >{String(record[field])}</TableCell>
        })
      }
      {canEdit && (

        <TableCell align='center'>
          <Link to={getEdit} relative='path' state={record}>
            แก้ไขข้อมูล
          </Link>
        </TableCell>
      )}
    </Fragment>
  )
}