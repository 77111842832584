import axios from 'axios'
// import { message } from 'antd'
import { deviceDetect } from 'react-device-detect'
import { idGenerate } from '../utils/helper'
import { identifierLocalStorage } from '../utils/localstorage'
import * as qs from 'qs'
// import { updateFirebaseToken } from './auth'
const { parse, stringify } = qs

const controller = new AbortController()

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}` || '/api',
  paramsSerializer: {
    encode: parse,
    serialize: stringify,
  }
})
instance.defaults.signal = controller.signal

instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    switch (error?.response?.status) {
      case 403:
        break
      case 502:
        return 'เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ'
      case 500:
        return 'เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ'
      case 401:
        // eslint-disable-next-line no-case-declarations
        const identifier = identifierLocalStorage.get()
        if (identifier !== null && !error.config.__isRetryRequest) {
          error.config._retry = true
          // eslint-disable-next-line no-undef
          return fetch(`${process.env.REACT_APP_API_URL}/auth/token`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({
              id: identifier.id,
              refreshToken: identifier.refreshToken,
              deviceId: identifier.deviceId
            }),
          }).then(res => res.json())
            .then(data => {
              if (data.error) {
                identifierLocalStorage.clear()
                return window.location.href = '/login'
              }
              const idRef = deviceDetect()
              const deviceType = idRef ? idRef.browserName + ', ' + idRef.osName : ''
              identifier.deviceId = idGenerate(deviceType)
              identifier.deviceType = deviceType
              identifier.jwtToken = data.jwtToken
              identifier.refreshToken = data.refreshToken
              identifierLocalStorage.set(identifier)
              setAuthorizationToken(data.jwtToken)
              error.config.headers.Authorization = `Bearer ${data.jwtToken}`
              // updateFirebaseToken()
              return instance(error.config)
            })
        }
    }
    return Promise.reject(error)
  },
)


export const handleRequest = async (fetching) => {
  try {
    return await fetching
  } catch (e) {
    // console.log('e = from handle Requset', e)
    return {
      error: getErrorMessage(e)
    }
  }

}

export const setAuthorizationToken = (token) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete instance.defaults.headers.common['Authorization']
  }
}

const getErrorMessage = (e) => {
  return {
    code: e?.response?.status || null,
    response: e?.response?.data || '',
  }
}

const caseErrorAmountDevice422 = (e) => {
  return {
    res: {
      code: e?.status,
      message: e?.data ? e?.data?.message : '',
      errorTesting: e?.data
    },
  }
}

// use case = เข้าผ่านรายละเอียด pretest หรือไม่ก็ผ่าน start pretest testing 
const caseErrorAccess403 = (e) => {
  try {
    if (e) {
      return {
        checkAccess: (e) => (e.data.statusCode === 'block_access_pretest'),
        res: {
          code: e?.status,
          message: e?.data ? e?.data?.message : '',
          errorTesting: e?.data
        },
      }
    }
  } catch (error) {
    return 'เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ'
  }
}

export default instance