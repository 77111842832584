import { Link } from 'react-router-dom'
import React from 'react'
import EnhancedTable from '../../components/table/EnhancedTable'
import { useQuery } from '@tanstack/react-query'
import styles from './User.module.css'
import { Button } from '@mui/material'
import { UserListAPI } from '../../api/user'

const User = () => {
  const { isLoading, error, data } = useQuery(['fetchUserList'], () => UserListAPI())
  return (
    <div>
      <div className={styles.headerUser}>
        <h1>รายชื่อผู้ใช้งาน</h1>
        <Link to={'/user/add'}>
          <Button variant="contained">เพิ่มผู้ใช้งาน</Button>
        </Link>
      </div>
      {error && <div> Error </div>}
      {isLoading && <div> ... loading </div>}
      {!error && !isLoading &&
        <EnhancedTable
          title={'รายชื่อผู้ใช้งาน'}
          list={data}
          canEdit
          options={{}}
          showFields={[
            'displayName',
            'email',
            'role',
            'follower',
            'following',
            'lastLogin',
            'createAt',
          ]}
        />}
    </div>
  )
}
export default User
// export default (props) => <QueryClientProvider client={queryClient}> <User {...props} /> </QueryClientProvider>
