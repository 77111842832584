import React, { Fragment, useState } from 'react'
import styles from '../QuestionForm.module.css'
import InputForm from '../../../components/form/Input/InputForm'
import InputOptionMulti from '../../../components/form/Input/InputOptionMulti'
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { LessonMock } from '../../../store/mock/mock'
import { Button, Divider } from '@mui/material'
import StepAndBody from '../../../components/step/StepAndBody'

const BodyFormQuestion = () => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  )
  const [activeStep, setActiveStep] = useState(0);
  const [contentStep, setContentStep] = useState([]);

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const addFormChoice = () => {
    const divChoice = document.getElementById('divChoice')
    const addElement = document.createElement('p')
    addElement.textContent = 'kkkkkkkkkk'
    divChoice.appendChild(addElement)
  }

  const deleteFormChoice = () => {
    const divChoice = document.getElementById('divChoice')
    const getChild = divChoice.children
    getChild[0].remove()
    console.log(getChild)
  }


  return (
    <Fragment>
      <StepAndBody
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      {
        activeStep === 0 ? (
          <div>
            <InputForm label='ชื่อคำถาม' />
            <InputOptionMulti
              label='เลือกบทเรียน'
              listItem={LessonMock}
            />
            <br />
            <Button variant='outlined'>Click Get State</Button>
            <Fragment>
              <div className={styles.formRichText}>
                <p className={styles.labelEditor}>รายละเอียดคำถาม</p>
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
              </div>
            </Fragment>
          </div>
        ) : activeStep === 1 ? (
          <div>
            <h3>Choice</h3>
            <Divider></Divider>
            <Button onClick={addFormChoice}>Add Choice</Button>
            <Button onClick={deleteFormChoice}>delete Choice</Button>
            <div className={styles.divChoice} id='divChoice'>
              <Fragment>
                <div className={styles.formRichText}>
                  <p className={styles.labelEditor}>Choice 1</p>
                  <Editor
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </div>
              </Fragment>
              <Fragment>
                <div className={styles.formRichText}>
                  <p className={styles.labelEditor}>Choice</p>
                  <Editor
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                </div>
              </Fragment>
            </div>
          </div>
        )
        :
        <div></div>
      }
    </Fragment>
  )
}


export default BodyFormQuestion