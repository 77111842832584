import React, { useState, useEffect } from 'react'
import styles from './User.module.css'
import { TextField, Button, FormControl } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  handleChange,
  // resetUser 
} from '../../store/coreModels/handleUser'
import {
  // UserDetailsAPI,
  UserEditAPI, UserCreateAPI
} from 'api/user'

const setTitle = (location) => {
  if (location.pathname.search('edit') <= -1) {
    return 'เพิ่มผู้ใช้งาน'
  }
  return 'แก้ไขผู้ใช้งาน'
}

export default function UserForm(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const state = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (location.pathname.search('edit') <= -1) {
      dispatch(handleChange({ name: 'displayName', value: '' }))
      dispatch(handleChange({ name: 'email', value: '' }))
      dispatch(handleChange({ name: 'password', value: '' }))
    } else {
      dispatch(handleChange({ name: 'displayName', value: location.state.displayName }))
      dispatch(handleChange({ name: 'email', value: location.state.email }))
      dispatch(handleChange({ name: 'password', value: location.state.password }))
    }
    return () => {
      dispatch(handleChange({ name: 'displayName', value: '' }))
      dispatch(handleChange({ name: 'email', value: '' }))
      dispatch(handleChange({ name: 'password', value: '' }))
    }
  }, [])

  const checkDisable = () => {

    if (!state) {
      return !state
    } else {
      if (state?.displayName) {
        return state?.displayName.trim() === ''
      }
      return true
    }
  }

  // const setTitle = ( )
  // const setDisableBtn = ( )
  // const setDefaultValue = ( )
  // const setAlertWarning = ( )
  // const setLoading = ( )
  // const setError = ( )

  const onSubmitUser = () => {
    setLoading(true)
    if (location.pathname.search('edit') <= -1) {
      UserCreateAPI(state).then(response => {
        if (!response?.error && response.acknowledged === true) {
          return navigate('/user')
        }
      })
      setLoading(false)
    } else if (location.pathname.split('/')[3]) {
      UserEditAPI(location.pathname.split('/')[3], state).then((response) => {
        if (!response?.error) {
          return navigate('/user')
        }
      })
      setLoading(false)
    }
    dispatch(handleChange({ name: 'displayName', value: '' }))
    dispatch(handleChange({ name: 'email', value: '' }))
    dispatch(handleChange({ name: 'password', value: '' }))
  }

  return (
    <div className={styles.rootForm}>
      <div className={styles.headerContent}>
        <h1> {setTitle(location)} </h1>
      </div>
      <div className={styles.layoutForm}>
        <FormControl>
          <div className={styles.inputForm}>
            <TextField
              autoComplete='off'
              name='displayName'
              size='small'
              value={state?.displayName ? state?.displayName : ''}
              onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
              label='ชื่อผู้ใช้'
              variant='outlined'
              sx={{ width: '400px' }} />
          </div>

          <div className={styles.inputForm}>
            <TextField
              autoComplete='off'
              name='email'
              size='small'
              type='email'
              value={state?.email ? state?.email : ''}
              onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
              label='อีเมล์'
              variant='outlined'
              sx={{ width: '400px' }} />
          </div>

          <div className={styles.inputForm}>
            <TextField
              autoComplete='off'
              name='password'
              size='small'
              type='password'
              value={state?.password ? state?.password : ''}
              onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
              label='รหัสผ่าน'
              variant='outlined'
              sx={{ width: '400px' }} />
          </div>

          <div className={styles.divBtnConfirm}>
            <Button variant='outlined' style={{ marginRight: 16 }} onClick={() => navigate(-1)}  >ยกเลิก</Button>
            <Button
              disabled={checkDisable() || loading}
              onClick={(e) => onSubmitUser(e)}
              variant='contained' >ตกลง</Button>
          </div>
        </FormControl>

      </div>
    </div>
  )
}
