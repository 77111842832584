import React, { Fragment, useState } from 'react'
import LayoutForm from '../../components/form/LayoutForm'
import BodyFormQuestion from './FormQuestion/BodyFormQuestion'

function QuestionForm() {

  return (
    <div>
      <LayoutForm
        header={<div> Question Form </div>}
        bodyForm={<BodyFormQuestion />}
      />
    </div>
  )
}

export default QuestionForm
