import { BodyContent } from "./stateConfig"

export const SetBodyComponent = ({ list, isItemSelected, labelId, row, showFields, canEdit }) => {
  return (
    <BodyContent
      canEdit={canEdit}
      list={list}
      isItemSelected={isItemSelected}
      labelId={labelId}
      record={row}
      showFields={showFields}
    />
  )
} 