import { Link } from 'react-router-dom'
import React from 'react'
import EnhancedTable from '../../components/table/EnhancedTable'
import { useQuery } from '@tanstack/react-query'
import styles from './Master.module.css'
import { Button } from '@mui/material'
// import { UserListAPI } from 'api/user'

const Master = () => {
  // const { isLoading, error, data } = useQuery(['fetchUserList'], () => UserListAPI())
  return (
    <div>
      <div className={styles.headerMaster}>
        <h1>Master Data</h1>
      </div>
      <div>
        <h3>Tag</h3>
        <h3>หมวดหมู่</h3>
        <h3>รูปแบบคำถาม</h3>
        <h3>Role</h3>
        <h3>Tag</h3>
        <h3>Tag</h3>

      </div>
      {/* {error && <div> Error </div>}
      {isLoading && <div> ... loading </div>}
      {!error && !isLoading &&
        <EnhancedTable />} */}
    </div>
  )
}
export default Master