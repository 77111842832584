import { createSlice, current } from '@reduxjs/toolkit'
import { checkMoblieBrowser } from '../../api/auth'
import { deviceDetect } from 'react-device-detect'
import { idGenerate } from '../../utils/helper'

const deviceType = deviceDetect() ? (deviceDetect().browserName || checkMoblieBrowser(deviceDetect())) + ', ' + (deviceDetect().osName || deviceDetect().os + `, ${deviceDetect().isMobile ? 'Mobile' : deviceDetect().isTablet && 'Tablet'}`) : ''

const defaultState = {
  email: undefined,
  username: undefined,
  password: undefined,
  deviceType: deviceType,
  deviceId: idGenerate(deviceType),
}

export const handleAuth = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    onChangeEmail: (state, action) => {
      // state = Object.assign(state, { email: action.payload })
      Object.assign(state, { email: action.payload })
    },
    onChangePassword: (state, action) => {
      state.password = action.payload
    },
    onChangeDevice: (state, action) => {
      console.log('action = ', action)
      state.deviceId = action.payload.deviceId
      state.deviceType = action.payload.deviceType
    },
  },
})


export const { onChangeEmail, onChangePassword, onChangeDevice } = handleAuth.actions
export default handleAuth.reducer