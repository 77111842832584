import moment from 'moment'
import md5 from 'md5'
import localization from 'moment/locale/th'
moment.updateLocale('th', localization)

export const idGenerate = (text) => {
  return md5(text)
}

export const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this, args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const setCookie = (cname, value, expiredHour = 0) => {
  let expires = ''
  if (expiredHour) {
    const date = new Date()
    date.setTime(date.getTime() + (expiredHour * 60 * 60 * 1000))
    expires = `expires=${date.toUTCString()};`
  }
  document.cookie = `${cname}=${value};${expires}path=/` // eslint-disable-line
}

export const deleteCookie = (cname) => {
  document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/` // eslint-disable-line
}

export const getCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie) // eslint-disable-line
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

export const isEmptyObj = (obj) => {
  return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

export const dateFromTimeStamp = (ts) => {
  const dt = moment(ts * 1000)
  return dt.isValid() ? dt.add(543, 'years').format('DD/MM/YYYY') : '-'
}
export const dateFromTimeStampThai = (ts) => {
  const dt = moment(ts * 1000)
  return dt.isValid() ? dt.add(543, 'years').format(' DD MMM YYYY') : '-'
}
export const dateAndTimeFromTimeStamp = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? dt.add(543, 'years').format('DD/MMM/YYYY, HH:mm') : '-'
}
export const dateAndTime = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? `${dt.format('DD MMMM')}${dt.add(543, 'years').format(' YYYY')}` : '-'
}
export const dateThai = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? dt.add(543, 'years').format('ll') : '-'
}
export const dateLL = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? dt.add(543, 'years').format('LL') : '-'
}
export const timeThai = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? dt.add(543, 'years').format('LT') : '-'
}

export const TimeHHMM = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? `${dt.format('HH:mm')}` : '-'
}

export const timeLTS = (ts) => {
  const dt = moment(ts)
  return dt.isValid() ? dt.format('LTS') : '-'
}

export const secoundToMinuts = (secound) => {
  const secounds = (parseInt(secound) % 60)
  const minuts = (parseInt(secound) / 60)
  return `${convertTime(minuts)}:${convertTime(secounds)}`
}
const convertTime = (time) => {
  return parseInt(time) === 0 || parseInt(time) < 10 ? '0' + parseInt(time) : parseInt(time)
}

export const isInArray = (array, finder) => -1 !== array.indexOf(finder)

export const cloneArray = (items) => items.map(item => Array.isArray(item) ? cloneArray(item) : item)

export const isJwtTokenValid = (jwtToken) => {
  if (!jwtToken) return false

  // eslint-disable-next-line no-undef
  const jwt = JSON.parse(window.atob(jwtToken.split('.')[1]))

  // multiply by 1000 to convert seconds into milliseconds
  if (!(jwt && jwt.exp)) return false

  const expireDate = jwt.exp * 1000

  // Check expire
  return expireDate > Date.now()
}

export const randomNumberBetween = (min, max) => {
  return Math.random() * (max - min) + min
}

export const monthTH = (numberMonth) => {
  switch (numberMonth) {
    case '01': return 'มกราคม'
    case '02': return 'กุมภาพันธ์'
    case '03': return 'มีนาคม'
    case '04': return 'เมษายน'
    case '05': return 'พฤษภาคม'
    case '06': return 'มิถุนายน'
    case '07': return 'กรกฎาคม'
    case '08': return 'สิงหาคม'
    case '09': return 'กันยายน'
    case '10': return 'ตุลาคม'
    case '11': return 'พฤศจิกายน'
    case '12': return 'ธันวาคม'
    default: return '-'
  }

}

export const encodeBase64 = (str) => {
  // eslint-disable-next-line no-undef
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)))
}

export const decodeBase64 = (str) => {
  // eslint-disable-next-line no-undef
  return decodeURIComponent(atob(str).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''))
}
export const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a)
  var bProps = Object.getOwnPropertyNames(b)

  // If number of properties is different,
  if (aProps.length !== bProps.length) return false

  for (var i = 0; i < aProps.length; i++) {
    const propName = aProps[i]
    if (a[propName] !== b[propName]) return false
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}
export const extractData = (res, handleChangeSubject, query) => {
  try {
    // const data = res.map( g => ({
    //   id : g.id,
    //   text : `${g.name} ${g.info ? g.info : ''}`,
    //   action: () => handleChangeSubject(query, g.id, `${g.name}${g.info ? g.info : ''}`)
    // }) )
    // let all = [{ 
    //   text: 'ทุกวิชา',
    //   id: '', 
    //   action: () => handleChangeSubject(query, '', 'ทุกวิชา')
    // }]
    // return all.concat(data)  
  } catch (e) {
    return []
  }
}


export function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

