import React from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
// import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
// import { decrement, increment, incrementByAmount } from '../store/features/counterSlice'

function Home() {
  const dispatch = useDispatch()
  const state = useSelector((state) => {
    return state
  })
  const count = state.counter.value

  // const [data, setData] = useState('')

  // const handleClick = (e) => {
  //   const params = { email: "hello@gmail.com", password: 'password' }
  //   axios.post('http://localhost:8000/login', params).then((res) => {
  //     console.log(res.data)
  //   }).catch((e) => {
  //     console.log(e.response?.data)
  //   })
  // }

  return (
    <div>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <h1>{count ? count : '-'}</h1>
            {/* <h1>{text ? text : '-'}</h1> */}
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            {/* <button onClick={handleClick}>Send Data</button> */}
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

          </Paper>
        </Grid>
      </Grid>
      {/* <button onClick={() => dispatch(increment())}> Increment</button>
      <button onClick={() => dispatch(decrement())}> Decrement</button>
      <button onClick={() => dispatch(incrementByAmount(5))}> Add 5 value</button> */}
    </div>
  )
}

export default Home
