import { createSlice } from '@reduxjs/toolkit'

const defaultValue = {
  title: '',
  subTitle: '',
  content: '',
  publicDate: '',
  public: false,
}

export const handleContent = createSlice({
  name: 'content',
  initialState: defaultValue,
  reducers: {
    handleChange: (state, action) => {
      state[action.payload.name] = action.payload.value
    },
    resetContent: (state) => {
      state = defaultValue
    }
  },
})

export const { handleChange, resetContent } = handleContent.actions
export default handleContent.reducer