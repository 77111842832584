import axios, { handleRequest, setAuthorizationToken } from './api-connecting'
import { deviceDetect } from 'react-device-detect'
import { idGenerate } from '../utils/helper'
import { identifierLocalStorage, clearAllLocal } from '../utils/localstorage'

export const checkMoblieBrowser = (idRef) => {
  if (idRef.isMobile || idRef.isTablet) {
    if (idRef.ua.includes('EdgA') || (idRef.os === 'iOS' && idRef.ua.includes('EdgiOS'))) {
      return 'Edge'
    }
    if (idRef.ua.includes('Chrome') || (idRef.os === 'iOS' && idRef.ua.includes('CriOS'))) {
      return 'Chrome'
    }
    if (idRef.ua.includes('Firefox') || (idRef.os === 'iOS' && idRef.ua.includes('FxiOS'))) {
      return 'Firefox'
    }
    if (idRef.ua.includes('Opera') || (idRef.os === 'iOS' && idRef.ua.includes('Opera'))) {
      return 'Opera'
    }
    if (idRef.ua.includes('Safari') || (idRef.os === 'iOS' && idRef.ua.includes('Safari'))) {
      return 'Safari'
    }
  }
  return undefined
}

const storeIdentifierLS = (user) => {
  identifierLocalStorage.set(user)
  setAuthorizationToken(user.tokens)
  return user
}



const attachDeviceInfo = (params) => {
  const idRef = deviceDetect()
  const deviceType = idRef ? (idRef.browserName || checkMoblieBrowser(idRef)) + ', ' + (idRef.osName || idRef.os + `, ${idRef.isMobile ? 'Mobile' : idRef.isTablet && 'Tablet'}`) : ''
  params.deviceId = idGenerate(deviceType)
  params.deviceType = deviceType
  return params
}

export const updateFirebaseToken = async (action) => {
  // if (identifierLocalStorage.get()) {
  //   if (action === 'logout') {
  //     const res = await resetFirebaseTokenService({ firebaseToken: '' })
  //     firebaseLS.clear()
  //     firebaseLS.set(res.currentDevice.firebaseToken)
  //   }
  //   try {
  //     let firebaseToken = null
  //     if (Object.values(await getTokenFierbase())[0] === true) {
  //       firebaseToken = Object.values(await getTokenFierbase())[1]
  //     } else {
  //       // if noti blocked values = messaging/permission-blocked
  //       firebaseToken = await getTokenFierbase()
  //     }
  //     if (localStorage.getItem('_ident') && action !== 'logout') {
  //       const res = await resetFirebaseTokenService({ firebaseToken })
  //       firebaseLS.clear()
  //       firebaseLS.set(res.currentDevice.firebaseToken)
  //       if (action === 'click:alert') {
  //         window.location.reload()
  //       }
  //     }
  //   } catch (error) {

  //   }
  // }
}

const LoginAPI = (params) => axios.post('/auth/login', params).then(user => storeIdentifierLS(user))
const LoginFacebookAPI = (params) => axios.post('/auth/login/fb', attachDeviceInfo(params)).then(user => storeIdentifierLS(user))
const RegisterAPI = (params) => axios.post('/auth/register', attachDeviceInfo(params)).then(user => storeIdentifierLS(user))
const GetProfileAPI = () => axios.get('/auth/profile').then(user => user)
const EditProfileAPI = (params) => axios.patch('/auth/profile', params)
const getPaymentHistoryAPI = () => axios.get('auth/payments')

const LogoutAPI = () => axios.post('/auth/logout', {}).then((response) => {
  updateFirebaseToken('logout')
  clearAllLocal()
  identifierLocalStorage.clear()
  return response
})
const RegenerateOtpAPI = () => axios.post('/auth/resend/otp', {}).then((response) => response)
const RegenerateVerifyLinkAPI = () => axios.post('/auth/resend/email', {})
const VerifyOtpAPI = (params) => axios.post('/auth/verify/otp', params)
const SendOTpAPI = (params) => axios.post('/auth/forgotPass', params)
const VerifyOTPAOI = (params) => axios.post('/auth/forgotPass/verifyOtp', params)
const resSetPassword = (param) => axios.patch('/auth/forgotPass/resetPassword', param)
const resetFirebaseTokenAPI = (param) => axios.patch('/auth/session', param)

export const loginService = (credentials) => handleRequest(LoginAPI(credentials))
export const loginFacebookService = (credentials) => handleRequest(LoginFacebookAPI(credentials))
export const registerService = (params) => handleRequest(RegisterAPI(params))
export const getProfileService = () => handleRequest(GetProfileAPI())
export const editProfileService = (params) => handleRequest(EditProfileAPI(params))
export const logoutService = () => handleRequest(LogoutAPI())
export const regenerateOtpService = () => handleRequest(RegenerateOtpAPI())
export const regenerateVerifyLinkService = () => handleRequest(RegenerateVerifyLinkAPI())
export const verifyOtpService = (params) => handleRequest(VerifyOtpAPI(params))
export const getPaymentHistoryService = () => handleRequest(getPaymentHistoryAPI())
export const sendOTPService = (params) => handleRequest(SendOTpAPI(params))
export const verifyOTPService = (params) => handleRequest(VerifyOTPAOI(params))
export const resetPassword = (param) => handleRequest(resSetPassword(param))
export const resetFirebaseTokenService = (param) => handleRequest(resetFirebaseTokenAPI(param))