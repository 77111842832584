import React from 'react'
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

export const AZURE_ASSETS_STORAGE_ACCOUNT_NAME = "kubsavesmastoragebackend";
export const AZURE_BLOB_STORAGE_URL = `https://kubsavemastoragebackend.blob.core.windows.net/`;
// sp=racwdli&st=2023-10-08T17:16:11Z&se=2023-10-11T01:16:11Z&sv=2022-11-02&sr=c&sig=9aKhMqRdlyRP1mdc8t3zxj86FIxOsKn72Pf%2BKtI0mk8%3D
const sasKeys = 'sv=2023-08-03&se=2023-10-09T23%3A19%3A40Z&sr=c&sp=racwdltf&sig=nwTh2vhRdMEFRT6%2FEH7LrqYJVASigUPJendzcqZhTfk%3D'

function TestLayout() {
  const handleChange = async (e) => {
    const blobServiceClient = new BlobServiceClient(
      `${AZURE_BLOB_STORAGE_URL}?${sasKeys}`
    );
    if (e.target.files[0]) {
      const containerClient = await blobServiceClient.getContainerClient('truck');
      const blockBlobClient = containerClient.getBlockBlobClient(e.target.files[0].name);
      const options = {
        blobHTTPHeaders: { blobContentType: e.target.files[0].type },
      }
      const uploadData = await blockBlobClient.uploadData(e.target.files[0], options)
      console.log('uploadData = ', uploadData)
    }

    console.log(e.target.files[0])
  }
  return (
    <div>
      <input type='file' onChange={handleChange} />
    </div>
  )
}

export default TestLayout