import * as React from 'react'
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
// import Badge from '@mui/material/Badge'
import Container from '@mui/material/Container'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import { MainListItems, SecondaryListItems } from './listMenuItem'
// import { useSelector, useDispatch } from 'react-redux'
import { AuthController, checkIsAdmin } from '../services/athentication/athentication'
import { WEBSITE_TITLE } from '../config/menu'
import { identifierLocalStorage } from '../utils/localstorage'
import { Copyright } from './Copyrigth'

const authservice = new AuthController()



const drawerWidth = 240

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      height: window.outerHeight,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
)
const mdTheme = createTheme()


export default function LayoutMember(props) {
  const [open, setOpen] = React.useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  if (!identifierLocalStorage.get()) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  if (!checkIsAdmin(identifierLocalStorage.get())) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  const handleLogout = (e) => {
    authservice.Logout()
    navigate("/")
  }

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <ThemeProvider theme={mdTheme}>
      {/* keep right padding when drawer closed */}
      <Toolbar sx={{ pr: '24px', }}  >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}  >
          {WEBSITE_TITLE}
        </Typography>
        <button className='signOutBtn' onClick={handleLogout}>Sign out</button>

        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">  
            <NotificationsIcon />
          </Badge>
        </IconButton> */}
      </Toolbar>
      {/* ส่วนของ bar */}
      <Box sx={{ display: 'flex' }}>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <MainListItems />
          </List>
          <Divider />
          <List>
            <SecondaryListItems />
          </List>
        </Drawer>
        {/* ส่วนของ Main Section เปิด */}
        <Box component="main" sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
        }}
        >
          <Container className='container outlet' maxWidth={open ? "md" : 'lg'} sx={{ mt: 2, mb: 8 }}>
            <Outlet {...props} />
          </Container>
        </Box>
      </Box>
      {/* ส่วนของ Main Section ปิด */}
      <Copyright sx={{ pt: 4 }} />
    </ThemeProvider>
  )
}
