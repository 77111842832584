import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LayoutMember from './layout/LayoutMember';
import Content from './page/content/Content';
import FormContent from './page/content/FormContent';
import Group from './page/group/Group';
import History from './page/history/History';
import Home from './page/Home';
import Question from './page/question/Question';
import User from './page/user/User';
import QuestionForm from './page/question/QuestionForm';
import LayoutLogin from './layout/LayoutLogin';
import UserForm from './page/user/UserForm'
import Setting from './page/Setting'
import Master from './page/master/Master';
import TestLayout from 'layout/TestLayout';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 10
    },
  }
})

const router = createBrowserRouter(createRoutesFromElements(
  <React.Fragment>
    <Route element={<LayoutMember />}>
      <Route path='/' element={<Home />} />
      <Route path='/content' element={<Content />} loader={async () => {
        return 'loader'
      }} />
      <Route path='/content/add' element={<FormContent />} />
      <Route path='/content/edit/:id' element={<FormContent />} />
      <Route path='/content/details' element={<FormContent />} />
      <Route path='/user' element={<User />} />
      <Route path='/user/add' element={<UserForm />} />
      <Route path='/user/edit/:id' element={<UserForm />} />
      <Route path='/user/details' element={<UserForm />} />
      <Route path='/group' element={<Group />} />
      <Route path='/group/add' element={<Group />} />
      <Route path='/group/edit' element={<Group />} />
      <Route path='/history' element={<History />} />
      <Route path='/question' element={<Question />} />
      <Route path='/question/add' element={<QuestionForm />} />
      <Route path='/master' element={<Master />} />
      <Route path='/master/catergories' element={<QuestionForm />} />
      <Route path='/master/tag' element={<QuestionForm />} />
      <Route path='/master/comment' element={<QuestionForm />} />
      <Route path='/setting' element={<Setting />} />
    </Route>
    <Route path='/login' element={<LayoutLogin />} />
    <Route path='/upload' element={<TestLayout />} />
  </React.Fragment>
))


function App() {



  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}


export default App;



{/* <BrowserRouter>
<Routes>
  <Route element={<LayoutMember />}>
    <Route path='/' element={<Home />} />
    <Route path='/content' element={<Content />} loader={async () => {
      const response = await fetch('http://localhost:80/admin/content/list', {
        method: 'GET', headers: {
          'Content-Type': 'application/json'
        },
      })
      return await response.json()
    }} />
    <Route path='/content/add' element={<FormContent />} />
    <Route path='/content/edit/:id' element={<FormContent />} />
    <Route path='/content/details' element={<FormContent />} />
    <Route path='/user' element={<User />} />
    <Route path='/user/add' element={<UserForm />} />
    <Route path='/user/edit/:id' element={<UserForm />} />
    <Route path='/user/details' element={<UserForm />} />
    <Route path='/group' element={<Group />} />
    <Route path='/group/add' element={<Group />} />
    <Route path='/group/edit' element={<Group />} />
    <Route path='/history' element={<History />} />
    <Route path='/question' element={<Question />} />
    <Route path='/question/add' element={<QuestionForm />} />
    <Route path='/master' element={<Master />} />
    <Route path='/master/catergories' element={<QuestionForm />} />
    <Route path='/master/tag' element={<QuestionForm />} />
    <Route path='/master/comment' element={<QuestionForm />} />
    <Route path='/setting' element={<Setting />} />
  </Route>
  <Route path='/login' element={<LayoutLogin />} />
</Routes>
</BrowserRouter >  */}
