import React, { useState, useEffect } from 'react'
import styles from './Content.module.css'
import { TextField, Button } from '@mui/material'

import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { handleChange } from '../../store/coreModels/handleContent'
import CKEditorInput from 'components/editor/CKEditorInput'
import { ContentCreateAPI, ContentEditAPI } from 'api/content'
// import { EditorState, convertToRaw } from 'draft-js'
// import { stateToHTML } from 'draft-js-export-html'
// import { Editor } from 'react-draft-wysiwyg'
// import DatePicker from 'react-datepicker'

export default function FormContent(props) {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(new Date())
  const location = useLocation()
  const state = useSelector((state) => state.content)
  const dispatch = useDispatch()
  // const [editorState, setEditorState] = useState(EditorState.createEmpty())
  // const onEditorStateChange = (value) => {
  //   // setEditorState(value)
  // }

  useEffect(() => {
    console.log(location)
    if (location.pathname.search('edit') <= -1) {
      dispatch(handleChange({ name: 'title', value: '' }))
      dispatch(handleChange({ name: 'subTitle', value: '' }))
      dispatch(handleChange({ name: 'content', value: '' }))
      dispatch(handleChange({ name: 'publicDate', value: '' }))
      dispatch(handleChange({ name: 'public', value: false }))
    } else {
      console.log('Edit Content')
      dispatch(handleChange({ name: 'title', value: location.state.title }))
      dispatch(handleChange({ name: 'subTitle', value: location.state.subTitle }))
      dispatch(handleChange({ name: 'content', value: location.state.content }))
      dispatch(handleChange({ name: 'publicDate', value: location.state.publicDate }))
      dispatch(handleChange({ name: 'public', value: location.state.public }))
    }
  }, [])



  const checkDisable = () => {
    if (!state) {
      return !state
    } else {
      if (state?.title) {
        return state?.title.trim() === ''
      }
      return true
    }
  }

  const onSubmitContent = () => {
    if (location.pathname.search('edit') <= -1) {
      ContentCreateAPI(state).then((res) => {
        if (!res.error) {
          return navigate('/content')
        }
      })
    } else if (location.pathname.split('/')[3]) {
      ContentEditAPI(location.pathname.split('/')[3], state).then((response) => {
        if (!response?.error) {
          return navigate('/content')
        }
      })
      // setLoading(false)
    }
  }

  return (
    <div className={styles.rootForm}>
      <div className={styles.headerContent}>
        <h1>เขียนบทความ</h1>
      </div>
      <div className={styles.layoutForm}>
        <div className={styles.inputForm}>
          <TextField
            autoComplete='off'
            name='title'
            size='small'
            value={state?.title ? state?.title : ''}
            onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
            label='หัวข้อ' variant='outlined'
            sx={{ width: '400px' }} />
        </div>

        <div className={styles.inputForm}>
          <TextField
            autoComplete='off'
            name='subTitle'
            size='small'
            value={state?.subTitle ? state?.subTitle : ''}
            onChange={(e) => dispatch(handleChange({ name: e.target.name, value: e.target.value }))}
            label='คำขึ้นต้น' variant='outlined'
            sx={{ width: '400px' }} />
        </div>

        {/* <h2>กำหนดวันที่เผยแพร่</h2>
        <DatePicker
          selected={new Date()}
          onChange={(date) => setStartDate(date)}
        /> */}

        <p>เนื้อหา</p>
        <div >
          <CKEditorInput
            defaultContent={state.content}
            handleChangeEditor={(event, editor) => {
              dispatch(handleChange({ name: 'content', value: editor.getData() }))
            }} />
        </div>

        <div className={styles.divBtnConfirm}>
          <Button variant='outlined' style={{ marginRight: 16 }} onClick={() => navigate(-1)}  >ยกเลิก</Button>
          <Button disabled={checkDisable()} onClick={(e) => onSubmitContent(e)} variant="contained" >ตกลง</Button>
        </div>

      </div>
    </div>
  )
}
